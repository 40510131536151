import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import exportVerifyReport from "../../views/VerifyManagement/http/exportVerifyReport";
import { stringify } from 'querystring';
import LoadingOverlay from '../../views/PublicDirectory/components/LoadingOverlay';
import setError from '../../store/error/actions/setError';


export interface DataTableQueryParams {
    readonly pageNumber: number;
    readonly orderBy?: string | number | symbol;
    readonly order: "asc" | "desc";
    readonly searchTerm: string;
    readonly [key: string]: string | string[] | number | symbol | undefined | boolean;
  
  }

  let notFiltered=true;
class SimpleMenu extends React.Component<{searchterms?:DataTableQueryParams}> {
    constructor(props: Readonly<{searchterms?:DataTableQueryParams}>) {
       super(props);

    }    
  state = {
    anchorEl: null,
    loading:false
  };

  handleClick = (event: { currentTarget: any; }) => {

    this.setState({ anchorEl: event.currentTarget });

  
    if(this.props.searchterms?.Country == undefined && this.props.searchterms?.City == undefined && this.props.searchterms?.Standard == undefined && this.props.searchterms?.Grade == undefined && this.props.searchterms?.CertificationStatus == undefined)
    {
      notFiltered=false;
   
    }
    else
    {
      notFiltered=true;

    }
    
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  
  render() {
    const { anchorEl } = this.state;
    
    return (
           <div>
         <LoadingOverlay isLoading={this.state.loading} />
        <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          Export List
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem  onClick={()=>
  {
   this.setState({ loading: true });
              
         exportVerifyReport(this.props.searchterms,"false")
         .catch((_error) => setError(_error))
        .finally(() => this.setState({ loading: false}));
            
                    }
  }>Export All</MenuItem>
          <MenuItem onClick={()=>
  {
    this.setState({ loading: true });
              
         exportVerifyReport(this.props.searchterms,"true")
         .catch((_error) => setError(_error))
         .finally(() => this.setState({ loading: false}));
            
                    }
                   
                    
  } disabled={this.props.searchterms?.showFavourited == false && this.props.searchterms?.searchTerm=="" && notFiltered== false? true: false }>Export Filtered</MenuItem>
         
        </Menu>
      </div>
    );
  }
}
export default SimpleMenu;
