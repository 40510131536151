import React, { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";
import logo from "../../../../assets/BRCGSDirectoryLogo.png";
import {
  about,
  contact,
  accessLogos,
  auditSiteSharing,
  trainingCourses,
} from "../../../../components/Paths/paths";
import styles from "./Header.module.scss";
import { AuditUrls } from "../../../AuditManagement";
import Icon, { IconType } from "../../../../components/Icon";

const headerItems = [
  { link: about, text: "About" },
  { link: trainingCourses, text: "Training Courses" },
  { link: auditSiteSharing, text: "Data Services" },
  { link: accessLogos, text: "Access & Logos" },
  { link: contact, text: "Contact" },
];

const PublicHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <nav className={styles.header}>
      <Link to="/" className={styles.logo}>
        <img src={logo} alt="BRCGS Directory Logo" />
      </Link>
      <ul className={clsx(styles.menuItems, { [styles.open]: menuOpen })}>
        {headerItems.map((item) => (
          <li>
            <a rel="noreferrer" target="_blank" href={item.link}>
              {item.text}
            </a>
          </li>
        ))}
        <li className={styles.login}>
          <Link to={AuditUrls.searchTable}>Login</Link>
        </li>
      </ul>
      <IconButton
        className={styles.toggleMenu}
        onClick={() => setMenuOpen((prev) => !prev)}
      >
        <Icon type={IconType.MENU} />
      </IconButton>
    </nav>
  );
};

export default PublicHeader;
