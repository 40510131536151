/* eslint-disable react/destructuring-assignment */

import Toolbar from "@material-ui/core/Toolbar";
import * as React from "react";
import DateRangePicker, { DateRangeValue } from "../../../DateRangePicker";
import SearchBox from "../../../SearchBox";
import styles from "./DataTableToolbar.module.scss";
import LinkProps from "../../../../Models/CommonProps/LinkModel";
import { IconType } from "../../../Icon";
import Icon from "../../../Icon";
import DataTableAddButton from "./DataTableAddButton";
import { ArrowDownwardOutlined } from "@material-ui/icons";
import exportUserReport from "../../../../views/UserManagement/http/exportUserReport";
import LoadingOverlay from "../../../../views/PublicDirectory/components/LoadingOverlay";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import UserRole from "../../../../Models/user/UserRole";
import { getUserRole } from "../../../../Authentication/Auth";
import SimpleMenu from "../../../VerifyReportManagement/Index";

export interface DataTableToolbarOptions {
  readonly searchField?: {
    readonly enable?: boolean;
    readonly placeholder?: string;
  };
  readonly enableDateRange?: boolean;
  readonly newEntityLink?: LinkProps;
}

interface DataTableToolbarProps {
  readonly options?: DataTableToolbarOptions;
  readonly setDateRange: (val: DateRangeValue) => void;
  readonly dateRange: DateRangeValue;
  readonly dataManager: { readonly changeSearchText: (val: string) => void };
  readonly onSearchChanged: (val: string) => void;
}

class DataTableToolbar extends React.Component<
  DataTableToolbarProps,
  { searchText: string ,loading:boolean}
> {
  constructor(props: any) {
    super(props);
    this.state = {
      searchText: props.searchText,
      loading: false,
    };
  }

  onSearchChange = (searchText: string) => {
    this.props.dataManager.changeSearchText(searchText);
    this.setState({ searchText });
  };

  render() {
  const str = window.location.pathname
  const pieces = str.split('/')
  const UrlCheck = pieces[pieces.length - 1];
  const userTab= UrlCheck=="user"?true:false;
  const adminUser=getUserRole();
  const showExport=adminUser==='BRCGS Admin'?true:false;
  const { searchText } = this.state;
    const { options, dateRange, setDateRange } = this.props;
    const { enableDateRange, searchField, newEntityLink } = options ?? {};
    const { enable = true, placeholder = "Search name" } = searchField ?? {};
    return (
      <Toolbar className={styles.tableToolbar}>
        
        {enable && (
          <SearchBox
            searchString={searchText}
            onUpdateSearch={(val) => this.onSearchChange(val)}
            handleClick={() => this.props.onSearchChanged(searchText)}
            placeHolderString={placeholder}
          />
        )}
        {enableDateRange && (
          <DateRangePicker
            fromValue={dateRange[0]}
            toValue={dateRange[1]}
            onChange={setDateRange}
          />
        )}
        {newEntityLink && (
          <div className={styles.actions}>
            <DataTableAddButton
              label={newEntityLink.label}
              link={newEntityLink.link}
              type={IconType.ADD}
              permissionTargets={newEntityLink.permissionAreas}
              state={newEntityLink.stateInfo}
            />
          </div>
        )}
       
                      {userTab && showExport&&  (
        <div style={{display: 'flex', justifyContent:'flex-end',paddingRight:10}}>
          <div style={{display: 'flex', justifyContent:'flex-end',paddingRight:10}}>
          <LoadingOverlay isLoading={this.state.loading} />
          
        <IconButton
  onClick={()=>
  {
    this.setState({ loading: true });
              console.log(this.state.loading);
              exportUserReport()
            
                  .finally(() => this.setState({ loading: false}));
  }
  }
>
  <FontAwesomeIcon icon={faDownload} />
  <span className="add">Export User</span>
</IconButton>



           </div>
         
                      </div>)}
     
      </Toolbar>
    );
  }
}

export default DataTableToolbar;