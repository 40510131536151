
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import  { DataTableQueryParams } from "../../../components/DataTable";
export default (
 params?:  DataTableQueryParams|undefined,
 filtering?:string |""
) => {
 
  return axiosApi
    .get(`${BackendServices.REPORT_SERVICE.REPORT}/${"GetExportVerifyReportXls"}/${filtering}`, {
      params,
      responseType: "blob",
      noGlobalError: true,
    })
    
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `${"VerifyReport"} - ${new Date().toLocaleString()}.${"xls"}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(async (error) => {
      let errorText;
      try {
        errorText = await error.response.data.text();
      } catch (e) {
        errorText = null;
      }
      return Promise.reject(
        error.response?.status === 400 && errorText ? errorText : "Error"
      );
    });
};
