import { useEffect, useState } from "react";
import { CompanySiteOptionType } from "../../../../components/CompanySiteSelector";
import VerifyAuditDetailsView from "../../../../Models/Verify/Models/VerifyAuditDetailsView";
import Option from "../../../../Models/forms/Option";
import ListEntry from "../../../../Models/CommonProps/ListEntry";
import { DEFAULT_DATE_FORMAT } from "../../../../util/dates/DateFormats";
import moment from "moment";
import InfoBox from "../../../../components/Infobox";
import React from "react";
import { LinkText } from "../../../../components/Common/hyperlinks";
import { AuditUrls } from "../../../AuditManagement";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

interface VerifyAuditDetailsProps {
    readonly mainDetails?: VerifyAuditDetailsView | null;
    readonly refresh: () => void;

}



function VerifyAuditDetails({
    mainDetails,
    refresh,

}: VerifyAuditDetailsProps) {

    {



        // const fullAddress = [
        //     mainDetails?.siteLocation?.addressLine1 || '',
        //     mainDetails?.siteLocation?.addressLine2 || '',
        //     mainDetails?.siteLocation?.addressLine3 || '',
        //     mainDetails?.siteLocation?.county || '',
        //     mainDetails?.siteLocation?.zipCode || '',
        //     mainDetails?.siteLocation?.city || '',
        //     mainDetails?.siteLocation?.country?.name || ''
        //   ].filter(Boolean).join('\n');
        const fullAddress: string[] = [
            mainDetails?.siteLocation?.addressLine1 || '',
            mainDetails?.siteLocation?.addressLine2 || '',
            mainDetails?.siteLocation?.addressLine3 || '',
            mainDetails?.siteLocation?.county || '',
            mainDetails?.siteLocation?.zipCode?.toString() || '',
            mainDetails?.siteLocation?.city || '',
            mainDetails?.siteLocation?.country?.name || ''
        ];

       
        const [isShared, setIsShared] = useState<boolean | null>(null);

        useEffect(() => {
            if (mainDetails?.id) {
                axiosApi
                    .get(`${BackendServices.AUDIT_SERVICE.AUDIT}/GetSharedAuditResult/${mainDetails.id}`)
                    .then((response) => {
                        setIsShared(response.data); // Assuming response.data is a boolean
                    })
                    .catch((error) => {
                        console.error("Error fetching audit share status:", error);
                        setIsShared(null);
                    });
            }
        }, [mainDetails?.id]);

        const auditDetailsColumn1: ListEntry[] = [


            {
                label: "Site Code",
                value: mainDetails?.site.id,
                required: true,
            },

            {
                label: "Site Name",
                value: mainDetails?.site.name || " ",
                required: true,
            },
            {
                label: "Address",
                value: fullAddress,
                required: true,
            },
            {
                label: "Site Address Contact Email",
                value: mainDetails?.siteContactEmail?.trim() || " ",
                required: true,
            },
            {
                label: "Standard",
                value: mainDetails?.standard?.name?.trim() || " ",
                required: true,
            },
            {
                label: "Categories",
                value: mainDetails?.category || " ",
                required: true,
            },
            {
                label: "Certification Body",
                value: mainDetails?.certificationBody?.name?.trim() || " ",
                required: true,
            },
            {
                label: "Scope",
                value: mainDetails?.scope?.trim() || " ",
                required: true,
            },
            {
                label: "Exclusion from Scope",
                value: mainDetails?.exclusion?.trim() || " ",
                required: true,
            },
            {
                label: "Additional Modules",
                value: mainDetails?.additionalModules?.toString() || ["--"],
                required: true,
            },
            {
                label: "Technical Contact",
                value: mainDetails?.technicalName?.trim() || " ",
                required: true,
            },
            {
                label: "Technical Contact Email",
                value: mainDetails?.technicalContact?.trim() || " ",
                required: true,
            },

        ]

        
        const auditDetailsColumn2: ListEntry[] = [

            {
                label: "Audit ID",
                value:
                isShared === true ? (
                    <LinkText
                        link={AuditUrls.details(mainDetails?.id ?? "")}
                        text={mainDetails?.id ?? ""}
                    />
                ) : (
                    mainDetails?.id ?? ""
                ),
                required: true,
            },
            {
                label: "Certification status",
                value: mainDetails?.certificationStatus?.name?.trim() || " ",
                required: true,
            },
            {
                label: "Audit Programme",
                value: mainDetails?.auditProgramme?.trim() || " ",
                required: true,
            },
            {
                label: "Grade",
                value: mainDetails?.grade?.trim() || " ",
                required: true,
            },
            {
                label: "Audit Start Date",
                value: moment(mainDetails?.startDate).format(DEFAULT_DATE_FORMAT) ?? "",
                required: true,
            },
            {
                label: "Issue Date",
                value: moment(mainDetails?.certificateIssueDate).format(DEFAULT_DATE_FORMAT) ?? "",
                required: true,
            },
            {
                label: "Expiry Date",
                value: moment(mainDetails?.certificateExpiryDate).format(DEFAULT_DATE_FORMAT) ?? "",
                required: true,
            },
            {
                label: "Audit Owner Name",
                value: mainDetails?.auditOwner?.name?.trim() || " ",
                required: true,
            },
            {
                label: "Audit Owner Id",
                value: mainDetails?.auditOwner?.id ?? " ",
                required: true,
            },
            {
                label: "Auditor Record Validation Date",
                value: moment(mainDetails?.validationDate).format(DEFAULT_DATE_FORMAT) ?? "",
                required: true,
            },
            {
                label: "Reaudit Due Date",
                value: moment(mainDetails?.startDate).format(DEFAULT_DATE_FORMAT) ?? "",
                required: true,
            },
            {
                label: "Commercial Contact",
                value: mainDetails?.commercialName?.trim() || " ",
                required: true,
            },
            {
                label: "Commercial Contact Email",
                value: mainDetails?.commercialContact?.trim() || " ",
                required: true,
            },
        ]

        const VerifyAuditDetails = [];
        VerifyAuditDetails.push(auditDetailsColumn1);
        VerifyAuditDetails.push(auditDetailsColumn2);

        return (

            <>

                <InfoBox
                    header="Details"
                    items={VerifyAuditDetails}
                    columns={2}
                />
            </>
        );
    }
}
export default VerifyAuditDetails;