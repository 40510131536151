import React, { useState } from "react";
import DataTable, { DataTableQueryParams } from "../../../components/DataTable";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import verifyColumns from "./util/verifyColumns";
import VerifyRow from "./models/VerifyRow";
import SearchResponses from "../../../Models/APIGetData/SearchResponses";
import useStandards from "../../../http/public/useStandards";
import mapFilter from "./util/mapFilter";
import useGetAuditStatuses from "../../../http/audit/useGetAuditStatuses";
import useGetCertificationStatuses from "../../../http/audit/useGetCertificationStatuses";
import VerifySummary from "../../../Models/Verify/VerifySummary";
import auditActions from "./util/auditActions";
import { VerifyUrls } from "../index";
import ContentPage from "../../../components/ContentPage";
import useGetProgramOwners from "../../../http/audit/useGetProgramOwners";
import { getUserRole, isBRCGSUser, isCBUser } from "../../../Authentication/Auth";
import useCountries from "../../../http/verify/useGetCountry";
import sortNameAlphanumerical from "../../../util/sorting/sortNameAlphanumerical";
import SimpleMenu from "../../../components/VerifyReportManagement/Index"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-ui/core";


const VerifySearchTable = () => {
  const [reportfilter, setreportfilter] = useState<
    (DataTableQueryParams)
  >();
  const adminUser=getUserRole();
  const showExport=adminUser==='BRCGS Admin'?true:false;


   const standardsFilter  ={
    "Agents and Brokers": "Agents and Brokers",
    "Consumer Products": "Consumer Products",
    "ETRS": "ETRS",
    "Food": "Food",
    "Gluten-Free": "Gluten-Free",
    "Packaging Materials": "Packaging Materials",
    "Plant Based": "Plant Based",
    "START!": "START!",
    "Storage and Distribution": "Storage and Distribution",
     };
const gradeFilter={
"AA+": "AA+",
"AA": "AA",
"A+": "A+",
"A": "A",
"B+": "B+",
"B":"B",
"C+":"C+",
"C":"C",
"D+":"D+",
"D": "D",
"Foundation +": "Foundation +",
"Foundation": "Foundation",
"No Grade": "No Grade"
}


const countryFilter  = mapFilter(useCountries().siteCountries.sort(sortNameAlphanumerical));

  const certificationFilter = mapFilter(
    useGetCertificationStatuses().certificationStatuses.sort(sortNameAlphanumerical)
  );

  const fetch = (
    params: DataTableQueryParams
  ): Promise<{ data: VerifyRow[]; totalPages: number }> => {
    setreportfilter(params);
    return axiosApi
      .get<SearchResponses<VerifySummary>>(BackendServices.AUDIT_SERVICE.VERIFY, {
        params,
      })
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultsList.map(
          (audit): VerifyRow => ({
            Id: audit.id.toString(),
            SiteName: audit.site.name,
            SiteId: audit.site.id,
            CertificationBody: audit.certificationBody.name,
            ProgramOwner: audit.programOwner,
            Standard: audit.standard.name,
            AuditOwner: audit.auditOwner,
            ExpiryDate:audit.expiryDate,
            IssueDate:audit.issueDate,
            CertificationStatus: audit.certificationStatus,
            AuditStatus: audit.auditStatus,
            Grade:audit.grade,
            Country:audit.country,
            City:audit.city,
            IsFavourited:audit.isFavourited,
            SharingPermissionName:audit.sharingPermissionName,
            HasCertificate:audit.hasCertificate
                     
          })
        ),
      }));
  };

  return (
    
    <ContentPage title="Verify">

<div style={{display: 'flex', justifyContent:'flex-end',paddingRight:40}}>
         <IconButton className="sizeSmall">
         <FontAwesomeIcon icon={faDownload} />
           <SimpleMenu searchterms={reportfilter}   ></SimpleMenu> 
           </IconButton>
 </div>
         
      <DataTable<VerifyRow>
        columns={verifyColumns}
        fetchRemoteData={fetch}
        filters={{
          Standard: () => standardsFilter,
          Country: () => countryFilter,
          CertificationStatus: () => certificationFilter,
          Grade: () => gradeFilter,
        }}
        rowActions={undefined}
        toolBarOptions={{
          newEntityLink: !isCBUser()? {
            label: "Add new audit",
            link: VerifyUrls.create(),
          }:undefined,
        }}
        favouriteFilter={{ enabled:true }}
        
       
      />
    </ContentPage>
  );
};

export default VerifySearchTable;
